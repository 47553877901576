import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Chip as MuiChip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import Icon from './Icon';
import { classNames } from '../utils';

const useStyles = makeStyles(theme => ({
  chip: {
    maxWidth: '100%',
    '& .MuiChip-root': {
      borderRadius: theme.borderRadius.small,
      border: ({ props, state }) => {
        switch (props.color) {
          default:
          case 'default':
            return state.selected
              ? `1px solid ${theme.color.background.grey}`
              : `1px solid ${theme.color.border.main}`;
          case 'primary':
            return state.selected
              ? `1px solid ${theme.color.primary.dark}`
              : `1px solid ${theme.color.primary.main}`;
          case 'secondary':
            return state.selected
              ? `1px solid ${theme.color.secondary.main}`
              : `1px solid ${theme.color.secondary.light}`;
          case 'warning':
            return state.selected
              ? `1px solid ${theme.color.warning.main}`
              : `1px solid ${theme.color.warning.main}`;
          case 'error':
            return state.selected
              ? `1px solid ${theme.color.error.main}`
              : `1px solid ${theme.color.error.main}`;
        }
      },
      backgroundColor: ({ props, state }) => {
        switch (props.color) {
          default:
          case 'default':
            return state.selected ? theme.color.background.grey : 'transparent';
          case 'secondary':
            return state.selected ? theme.color.secondary.light : 'transparent';
          case 'warning':
            return state.selected ? theme.color.warning.main : 'transparent';
          case 'error':
            return state.selected ? theme.color.error.main : 'transparent';
        }
      },
      margin: 0,
      height: ({ props }) => {
        switch (props.size) {
          default:
          case 'default':
            return '1.8rem';
          case 'small':
            return '1.2rem';
        }
      }
    },
    '& .MuiChip-label': {
      color: ({ props, state }) => {
        switch (props.color) {
          default:
          case 'default':
          case 'primary':
            return theme.color.text.main;
          case 'secondary':
            return state.selected
              ? theme.color.secondary.contrastText
              : theme.color.text.main;
          case 'warning':
            return state.selected
              ? theme.color.text.main
              : theme.color.warning.dark;
          case 'error':
            return state.selected
              ? theme.color.error.contrastText
              : theme.color.text.main;
        }
      },
      padding: ({ props }) => {
        switch (props.size) {
          default:
          case 'default':
            return '0 0.75rem';
          case 'small':
            return '0 0.25rem';
        }
      },
      fontSize: theme.font.size.small
    }
  },
  closeButton: {
    color: theme.color.common.grey.main
  }
}));

export default function Chip(props) {
  const [selected, setSelected] = useState(props.selected);
  const classes = useStyles({ props, state: { selected } });

  function handleClick() {
    if (props.onClick) {
      setSelected(!selected);
      props.onClick();
    }
  }

  function handleDelete() {
    props.onClose();
  }

  useEffect(() => {
    if (props.selected !== selected) {
      setSelected(props.selected);
    }
  }, [props.selected]);

  return (
    <Box
      className={classNames([classes.chip, props.className])}
      title={props.title}
      component={props.component}
    >
      <MuiChip
        clickable={!!props.onClick}
        label={props.children}
        onClick={handleClick}
        onDelete={typeof props.onClose === 'function' ? handleDelete : null}
        deleteIcon={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.closeButton}
          >
            <Icon name="close" />
          </Box>
        }
      ></MuiChip>
    </Box>
  );
}

Chip.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'error',
    'warning'
  ]),
  onClose: PropTypes.func,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default'])
};

Chip.defaultProps = {
  onClick: undefined,
  onClose: undefined,
  selected: false,
  size: 'default'
};
