import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: props => (props.disabled ? 'not-allowed' : 'pointer'),
    userSelect: 'none',
    '&:focus-visible': {
      outline: `2px auto ${theme.color.primary.dark}`,
      outlineOffset: '-2px'
    },
    border: 0,
    padding: 0,
    background: 'transparent',
    fontSize: theme.font.size.text
  }
}));

export default function Clickable(props) {
  const classes = useStyles(props);

  const forwardedProps = { ...props };
  delete forwardedProps.linkComponent;

  // use ...props to make sure '@mui/core/Box' props get applied
  const clickable = (
    <Box
      {...forwardedProps}
      component="button"
      onClick={e => {
        if (!props.disabled) props.onClick(e);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter' && !props.disabled) {
          props.onClick(e);
        }
      }}
      onMouseEnter={e => {
        if (!props.disabled)
          typeof props.onMouseEnter === 'function' && props.onMouseEnter(e);
      }}
      onMouseLeave={e => {
        if (!props.disabled)
          typeof props.onMouseLeave === 'function' && props.onMouseLeave(e);
      }}
      className={`${classes.clickable} ${props.className}`}
      tabIndex={0}
    >
      {props.children}
    </Box>
  );

  if (props.href) {
    return (
      <props.linkComponent
        href={props.href}
        className={`${classes.clickable} ${props.className}`}
      >
        {props.children}
      </props.linkComponent>
    );
  } else {
    return clickable;
  }
}

Clickable.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  linkComponent: PropTypes.any,
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

Clickable.defaultProps = {
  className: undefined,
  disabled: false,
  linkComponent: 'a',
  href: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined
};
