export const theme = {
  color: {
    background: {
      default: '#FFFFFF', // standard white background
      primary: '#000000', // blue login background Flensburg
      secondary: '#9B0000', // red, unused so far
      grey: '#F5F5F5'
    },
    border: {
      light: '#DDDDDD', // disabled
      main: '#D1D1D1', // standard
      dark: '#0076dd' // focused
    },
    error: {
      // text box error, invalid information
      light: '#fdecee',
      main: '#d52f2e',
      contrastText: '#9b0000' // white text within buttons
    },
    warning: {
      main: '#F9F4E4',
      light: '#F9F4E4'
    }, // yellow
    success: '#397F62',
    text: {
      light: '#454545', // light text
      main: '#000000', // standard text
      dark: '#000000', // dark Text
      link: '#0047D3' // link Text color
    },
    common: {
      // holds icon/line/frame/border colors
      white: '#FFFFFF', // unused so far?
      black: '#000000', // unused so far?
      grey: {
        light: '#b3b3b3', // disabled
        main: '#7a7a7a', // standard
        dark: '#545454' // focused
      }
    },
    primary: {
      light: '#7f8d9e', // disabled
      main: '#001C3E', // standard
      dark: '#000000', // focused
      contrastText: '#FFFFFF' // white text within buttons
    },
    secondary: {
      light: '#EF7A75', // disabled
      main: '#FF4F4F', // standard
      dark: '#CD1C15', // focused
      contrastText: '#FFFFFF' // text
    }
  },
  font: {
    primary: 'Arial, sans-serif',
    secondary: '',
    size: {
      root: '16px', // base size for following sizes
      input: '1rem', // input components
      text: '1rem', // standard text
      small: '.875rem', // small text
      large: '1.125rem' // large text
    }
  },
  effect: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px'
    // if blurRadius needs to be increased,
    // check optical consequences on Tabbars active tab and fix them
  },
  borderRadius: {
    small: '.25rem',
    medium: '.5rem',
    large: '.75rem'
  },
  mediaQueries: {
    tiny: '@media (max-width: 37rem)',
    small: '@media (max-width: 64rem)',
    medium: '@media (max-width: 92rem)',
    large: '@media (min-width: 92rem)'
  }
};

export const customization = {
  components: {
    heading: {
      h1: {
        fontSize: '1.6875rem'
      },
      h2: {
        fontSize: '1.375rem',
        borderLeft: props =>
          props.icon ? null : `.4375rem solid ${theme.color.secondary.main}`, // secondary main
        paddingLeft: '.7rem',
        margin: props => (props.context ? 0 : '0 0 1rem 0')
      },
      h3: {
        fontSize: '1.0625rem',
        lineHeight: '1.4375rem'
      },
      h4: {
        fontSize: '1rem',
        lineHeight: '1.4375rem',
        marginBottom: '.4rem',
        marginTop: '1rem'
      },
      icon: {
        fill: theme.color.secondary.main,
        marginBottom: props => {
          switch (props.type?.toLowerCase()) {
            case 'h2':
              return props.context ? null : '1rem';
            default:
              return null;
          }
        }
      }
    },
    textField: {
      root: {
        '& .MuiInputLabel-root': {
          fontWeight: 'bold',
          transform: 'none',
          marginBottom: '.4rem',
          color: theme.color.text.main
        },
        '& .MuiOutlinedInput-root': {
          color: theme.color.text.main,
          background: theme.color.background.default
        },
        '& .MuiOutlinedInput-input': {
          padding: '.5rem .75rem'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${theme.color.text.main}`,
          borderRadius: 0
        },
        '& .Mui-error .MuiOutlinedInput-notchedOutline': {
          borderLeft: `6px solid ${theme.color.error.main}`
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.color.border.dark,
          boxShadow: `0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(102 175 233 / 60%)`
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: theme.color.text.main
        },
        '& .MuiFormHelperText-root.Mui-error': {
          background: theme.color.error.light,
          margin: 0,
          color: theme.color.error.contrastText,
          padding: '1.125rem',
          fontSize: '1rem'
        },
        '& .MuiFormHelperText-root': {
          fontSize: '.6875rem',
          marginLeft: 0,
          lineHeight: 1.4
        },
        '& svg': {
          color: props =>
            props.disabled
              ? theme.color.common.grey.main
              : theme.color.text.main
        }
      }
    },
    topbar: {
      root: {
        padding: 0
      }
    },
    timePicker: {
      root: {
        width: '6rem'
      }
    },
    label: {
      root: {
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '1.4375em',
        color: theme.color.text.main,
        '& svg': {
          width: '1.75rem',
          height: '1.75rem'
        }
      }
    },
    button: {
      root: {
        borderRadius: 0,
        padding: props => {
          if (props.icon) return null;

          if (props.endIcon) return '0 0 0 1rem';

          return '0 1rem';
        },
        lineHeight: '2.45rem',
        marginLeft: '.5rem',
        minHeight: props => (props.icon ? '2rem' : null),
        borderWidth: props => (props.variant === 'contained' ? '0px' : '2px'),
        '& svg': {
          fill: props => {
            if (props.icon) {
              if (props.color === 'secondary') {
                return props.disabled
                  ? theme.color.common.grey.main
                  : theme.color.secondary.main;
              }

              return theme.color.text.main;
            }

            return theme.color.primary.contrastText;
          }
        },
        borderColor: props => {
          if (props.endIcon) {
            return props.disabled
              ? theme.color.border.light
              : theme.color.text.dark;
          }

          if (props.variant === 'text') {
            return 'transparent';
          }

          if (props.color === 'primary') {
            return theme.color.primary.main;
          }

          return props.disabled
            ? theme.color.primary.light
            : theme.color.border.main;
        },
        '&:hover': {
          textDecoration: 'none',
          background: props => {
            if (props.variant === 'contained') {
              return props.disabled ? null : theme.color.primary.dark;
            }

            if (props.variant === 'text') {
              return 'transparent';
            }

            return props.disabled ? null : theme.color.background.grey;
          }
        },
        '&:focus-visible': {
          outline: `3px solid ${theme.color.border.dark}`,
          outlineOffset: 0,
          boxShadow: `0px 0px 0 5px ${theme.color.background.default}`
        },
        [theme.mediaQueries.tiny]: {
          width: props => (props.icon ? 'auto' : '100% !important'),
          marginTop: '.25rem',
          marginBottom: '.25rem',
          marginLeft: 0
        }
      },
      text: {
        marginRight: props => (props.endIcon ? '1rem' : null),
        fontWeight: 'normal',
        color: props => {
          if (props.variant === 'contained') {
            return props.disabled
              ? theme.color.primary.contrastText
              : theme.color.primary.contrastText;
          }

          return props.disabled
            ? theme.color.text.light
            : theme.color.text.main;
        },
        fontSize: props => {
          switch (props.size) {
            case 'small':
              return '0.8em';
            default:
            case 'medium':
              return '1em';
            case 'large':
              return '1.2rem';
          }
        }
      },
      endIcon: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '2.45rem',
        width: '2.45rem',
        margin: 0,
        background: props => {
          if (props.color === 'default' || props.color === 'light') {
            return theme.color.common.grey.main;
          }

          if (props.color === 'primary') {
            return props.disabled
              ? theme.color.primary.light
              : theme.color.primary.main;
          }

          if (props.color === 'secondary') {
            return props.disabled
              ? theme.color.secondary.light
              : theme.color.secondary.main;
          }
        }
      }
    },
    typeahead: {
      root: {
        '& .MuiOutlinedInput-input': {
          padding: '0 !important'
        }
      },
      loadMore: {
        '& button:hover': {
          background: theme.color.common.grey.light
        }
      }
    },
    message: {
      root: {
        background: props => {
          switch (props.type) {
            default:
            case 'default':
              return '#ebf1f7';
            case 'error':
              return `${theme.color.error.light}`;
            case 'warning':
              return theme.color.warning.light;
          }
        },
        '& svg': {
          display: 'none'
        },
        padding: '1.125rem'
      },
      text: {
        color: props => {
          switch (props.type) {
            default:
            case 'default':
              return theme.color.text.main;
            case 'error':
              return `${theme.color.error.contrastText}`;
            case 'warning':
              return theme.color.warning.light;
          }
        }
      }
    },
    text: {
      root: {
        lineHeight: 1.5,
        marginTop: 0
      }
    },
    multiCreate: {
      root: {
        '& .MuiFormHelperText-root.Mui-error': {
          background: theme.color.error.light,
          margin: 0,
          color: theme.color.error.contrastText,
          padding: '1.125rem',
          fontSize: '1rem'
        }
      },
      item: {
        background: theme.color.background.default,
        border: `2px solid ${theme.color.text.main}`,
        borderRadius: 0
      }
    },
    multiSelect: {
      dropdown: {
        background: theme.color.background.default,
        borderRadius: 0,
        border: `2px solid ${theme.color.text.main}`,
        '&:hover': {
          border: `2px solid ${theme.color.text.main}`
        },
        '&:has(.Mui-focusVisible)': {
          borderColor: theme.color.border.dark,
          boxShadow: `0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(102 175 233 / 60%)`
        }
      },
      tag: {
        borderRadius: 0
      },
      tagName: {
        maxWidth: '20rem',
        [theme.mediaQueries.tiny]: {
          maxWidth: '8em'
        }
      },
      selectedCounter: {
        borderRadius: 0
      },
      removeAllTags: {
        '& svg': {
          height: '.75em',
          transform: 'translate(0) !important',
          width: '.75em',
          fill: theme.color.text.main
        }
      },
      dropdownArrowIcon: {
        '& svg': {
          fill: theme.color.text.main
        }
      },
      searchInput: {
        '& ::placeholder': {
          fontSize: '1rem',
          color: theme.color.text.light,
          opacity: 1
        }
      },
      selectedItems: {
        '& svg': {
          background: 'transparent'
        }
      },
      placeholder: {
        color: theme.color.text.main,
        fontSize: '1rem'
      },
      infoText: {
        fontSize: '1rem'
      }
    },
    multiToggle: {
      group: {
        '& .MuiButtonGroup-grouped:not(:first-of-type)': {
          marginLeft: '-2px !important'
        }
      },
      root: {
        borderRadius: 0,
        borderWidth: '2px',
        borderColor: theme.color.border.light,
        '&:hover': {
          borderWidth: '2px',
          borderColor: theme.color.border.dark
        }
      },
      activeToggle: {
        borderWidth: '2px !important',
        zIndex: 1,
        '&:hover': {
          borderRightColor: `${theme.color.primary.main} !important`
        }
      }
    },
    checkbox: {
      root: {
        '& input[type="checkbox"]': {
          display: 'block',
          width: '1rem',
          height: '1rem'
        }
      },
      box: {
        display: 'none'
      },
      label: {
        fontWeight: 'normal',
        fontSize: '1rem'
      }
    },
    weekPicker: {
      firstDay: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      },
      lastDay: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      },
      dayBetween: {
        '&:hover, &:focus': {
          backgroundColor: `${theme.color.primary.main} !important`
        }
      }
    },
    pagination: {
      '& li': {
        border: `1px solid ${theme.color.common.grey.light}`
      },
      '& .MuiPaginationItem-root': {
        fontSize: '1rem',
        color: theme.color.text.main,
        margin: 0,
        padding: '.4rem 1.1rem .4rem 1.1rem',
        height: '2.2rem',
        borderRadius: 0,
        '&:hover': {
          textDecoration: 'underline'
        },
        '&:focus-visible': {
          outline: `1px auto ${theme.color.primary.dark}`,
          background: 'transparent'
        }
      },
      '& .MuiPaginationItem-root.Mui-selected': {
        background: 'none',
        borderTop: `4px solid ${theme.color.text.main}`,
        fontWeight: 'bold',
        borderRadius: 0,
        padding: 'calc(.4rem - 4px) 1.1rem .4rem 1.1rem ',
        '&:hover': {
          textDecoration: 'none'
        }
      },
      '& .MuiTouchRipple-root': {
        display: 'none'
      }
    },
    moneyInput: {
      root: {
        width: '7rem'
      }
    },
    currencyTextField: {
      root: {
        '& input': {
          paddingLeft: '0 !important'
        }
      }
    },
    hoverbox: {
      closeButton: {
        position: 'relative',
        transform: 'none',
        top: 0,
        right: 0,
        borderRadius: 0,
        background: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        '& svg': {
          fill: theme.color.text.main
        }
      }
    },
    icon: {
      svg: {
        height: props => {
          switch (props.size) {
            case 'small':
              return '0.875em';
            case 'medium':
            default:
              return '1em';
            case 'large':
              return '1.5em';
            case 'fullsize':
              return '100%';
          }
        },
        width: props => {
          switch (props.size) {
            case 'small':
              return '0.875em';
            case 'medium':
            default:
              return '1em';
            case 'large':
              return '1.5em';
            case 'fullsize':
              return '100%';
          }
        }
      }
    },
    breadcrumb: {
      root: {
        borderBottom: 0,
        paddingBottom: 0
      }
    },
    informationGrid: {
      item: {
        '& td': {
          padding: `0 0 .75rem 0`
        }
      }
    }
  }
};
