import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { IconButton } from '@mui/material';
import { Portal } from '@mui/base';
import { makeStyles } from '@mui/styles';

import Icon from './Icon';
import Paper from './Paper';
import Void from './Void';

import ModalActions from './ModalActions';
import ModalContent from './ModalContent';
import ModalHeading from './ModalHeading';

const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: 'rgba(0,0,0, 0.4)',
    fontFamily: theme.font.primary,
    height: '100vh',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 99
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  window: {
    background: theme.color.background.default,
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '80%',
    overflow: 'auto',
    minWidth: '15rem',
    maxWidth: '95%',
    position: 'relative',
    width: props => {
      switch (props.size) {
        case 'small':
          return '25rem';
        case 'medium':
          return '30rem';
        case 'large':
          return '45rem';
        case 'huge':
          return '70rem';
        default:
          return 'auto';
      }
    }
  }
}));

export default function Modal(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(props.open);
  const openRef = useRef(open);

  useEffect(() => setOpen(props.open), [props.open]);

  useEffect(() => {
    openRef.current = open;

    // Hide scrollbar on modal open
    document.body.style.overflow = open ? 'hidden' : null;
  }, [open]);

  const handleCloseEsc = useCallback(event => {
    if (event.key === 'Escape' && openRef.current === true) {
      setOpen(false);
      props.onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleCloseEsc, false);

    return () => {
      document.removeEventListener('keydown', handleCloseEsc, false);
      document.body.style.overflow = null;
    };
  }, []);

  return open ? (
    <Portal>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.modal}
        // onClick={e => e.stopPropagation()}
      >
        <Paper className={classes.window} p={2}>
          {typeof props.onClose === 'function' && props.closeButton && (
            <div className={classes.closeButton}>
              <IconButton
                aria-label={
                  props.closeButtonAriaLabel
                    ? props.closeButtonAriaLabel
                    : 'close'
                }
                onClick={() => {
                  setOpen(false);
                  props.onClose();
                }}
                size="small"
                title={
                  props.closeButtonTitle ? props.closeButtonTitle : 'close'
                }
              >
                <Icon name="close" />
              </IconButton>
            </div>
          )}
          {props.children}
        </Paper>
      </Box>
    </Portal>
  ) : (
    <Void />
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
  onClose: PropTypes.func,
  closeButton: PropTypes.bool,
  closeButtonAriaLabel: PropTypes.string,
  closeButtonTitle: PropTypes.string
};

Modal.defaultProps = {
  open: false,
  onClose: null,
  size: undefined,
  closeButton: true,
  closeButtonAriaLabel: 'close',
  closeButtonTitle: 'close'
};

Modal.Heading = ModalHeading;
Modal.Content = ModalContent;
Modal.Actions = ModalActions;
